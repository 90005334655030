import {useEffect, useRef} from 'react'

import {useFeatureFlags, useFeatureFlagsLoaded} from '@/feature-flags'

export type HubspotFlagStatus = {
    isEnabled: boolean
    isLoading: boolean
}

/**
 * Hook to check if the Hubspot feature flag is enabled
 *
 * @returns {isEnabled, isLoading} - The feature flag status
 */
export function useHubspotFeatureFlag(): HubspotFlagStatus {
    const isLoaded = useFeatureFlagsLoaded()
    const featureFlags = useFeatureFlags()

    // We can't use the flag value until LaunchDarkly has loaded
    const isLoading = !isLoaded
    const isEnabled = isLoaded ? featureFlags.hubspotBanner : false

    return {
        isLoading,
        isEnabled,
    }
}

/**
 * Hook to manage feature-dependent actions.
 *
 *  This hook will execute the `onEnabled` callback if the feature is enabled,
 *  and the `onDisabled` callback if the feature is disabled.
 *
 *  If the feature flag is still loading, no action will be taken.
 *
 * @param featureFlag The feature flag status
 * @param onEnabled Callback to execute when the feature is enabled
 * @param onDisabled Callback to execute when the feature is disabled
 */
export const useFeatureFlagDependentAction = ({
    featureFlag,
    onEnabled,
    onDisabled,
}: {
    featureFlag: HubspotFlagStatus
    onEnabled: () => void
    onDisabled: () => void
}) => {
    const isInitialisedRef = useRef(false)

    useEffect(() => {
        // Do nothing if the flag is still loading or the context is already initialised
        if (featureFlag.isLoading || isInitialisedRef.current) {
            return
        }

        // Set the context as initialised
        isInitialisedRef.current = true

        if (featureFlag.isEnabled) {
            // If the flag is enabled, execute the onEnabled callback
            onEnabled()
        } else {
            // Otherwise, execute the onDisabled callback
            onDisabled()
        }
    }, [featureFlag.isLoading, featureFlag.isEnabled, onEnabled, onDisabled])
}
