import React, {createContext, useCallback, useContext, useState} from 'react'

import {hubspotAPI} from './api'
import type {HubspotFlagStatus} from './hooks'
import {useFeatureFlagDependentAction, useHubspotFeatureFlag} from './hooks'
import {isEqual, omit} from './utils'

/**
 * Default consent state.
 *
 * All consent categories are set to false by default, to align with GDPR.
 */
export const defaultConsentState: Hubspot.ConsentPreferences = {
    allowed: false,
    categories: {
        analytics: false,
        advertisement: false,
        functionality: false,
        necessary: false,
    },
}

/**
 * Consent granted state.
 *
 * All consent categories are set to true. Used for when the feature flag is disabled.
 */
export const consentGrantedState: Hubspot.ConsentPreferences = {
    allowed: true,
    categories: {
        analytics: true,
        advertisement: true,
        functionality: true,
        necessary: true,
    },
}

/**
 * Context to hold Hubspot consent state and commands.
 */
export const HubspotConsentContext = createContext<{
    isEnabled: boolean
    isLoading: boolean
    consentPreferences: Hubspot.ConsentPreferences
} | null>(null)

/**
 * Internal hook to manage Hubspot consent preference updates.
 */
const useHubspotConsentPreferences = ({featureFlag}: {featureFlag: HubspotFlagStatus}) => {
    const [consentPreferences, setConsentPreferences] =
        useState<Hubspot.ConsentPreferences>(defaultConsentState)

    const updateConsentPreferences = useCallback(
        (newConsentPreferences: Hubspot.ConsentPreferences) => {
            // Only update if the new preferences are different
            if (isEqual(consentPreferences, newConsentPreferences)) {
                return
            }

            // Update state with the new preferences
            setConsentPreferences(newConsentPreferences)
        },
        [consentPreferences],
    )

    const registerConsentHandler = useCallback(() => {
        // Register the Hubspot consent listener
        hubspotAPI.addPrivacyConsentListener((preferences) => {
            updateConsentPreferences(omit(preferences, 'previousCategories'))
        }) // Note: The hubspot API does not support removing this listener
    }, [updateConsentPreferences])

    const setConsentPreferencesToGranted = useCallback(() => {
        // Set consent preferences to granted if the feature flag is disabled
        setConsentPreferences(consentGrantedState)
    }, [setConsentPreferences])

    /**
     * If flag is enabled, register a Hubspot consent change handler.
     * Otherwise, set consent preferences to granted.
     */
    useFeatureFlagDependentAction({
        featureFlag,
        onEnabled: () => registerConsentHandler(),
        onDisabled: () => setConsentPreferencesToGranted(),
    })

    return {consentPreferences}
}

/**
 * Provider for Hubspot consent state.
 */
export const HubspotConsentProvider = ({children}: {children: React.ReactNode}) => {
    const featureFlag = useHubspotFeatureFlag()
    const {consentPreferences} = useHubspotConsentPreferences({featureFlag})

    return (
        <HubspotConsentContext.Provider value={{...featureFlag, consentPreferences}}>
            {children}
        </HubspotConsentContext.Provider>
    )
}

/**
 * Hook to access Hubspot consent state.
 */
export const useHubspotConsent = () => {
    const context = useContext(HubspotConsentContext)
    if (!context) {
        throw new Error('useHubspotConsent must be used within a HubspotConsentProvider')
    }
    return context
}
