import Cookies from 'js-cookie'

import {cookiesMap} from '@/config/cookies'
import {usePageInfo} from '@/providers/page-info'
import type {HubspotContactProps} from '@/types/hubspot-contact-props'
import {
    MarketingCampaigns,
    Events,
    Integrations,
    LifecycleStages,
    HubspotPartnerChannel,
    HubspotPartnerSegment,
    HubspotPartnershipType,
    HubspotTypeOfInfluence,
    LeadType,
} from '@/types/marketing-campaigns'

export const useHubspotProperties = () => {
    const {location} = usePageInfo()
    const landingPage = Cookies.get(cookiesMap.rawLandingPageKey)
    const urlParams = new URLSearchParams(location.search)
    const sourceIsBlog = urlParams.get('utm_source') === 'blog'
    const utmCampaign = urlParams.get('utm_campaign')
    const integrationPage =
        location.pathname.split('/')[2] === 'integrations' ? location.pathname.split('/')[3] : ''
    const intergrationMarketplace = urlParams.get('pleo_partner_id') || ''

    const hubspotFields: Partial<HubspotContactProps> = {
        lifecyclestage: LifecycleStages.MQL,
    }

    if (integrationPage || intergrationMarketplace) {
        let pleoPartnerId, partnerChannel

        if (intergrationMarketplace) {
            pleoPartnerId = intergrationMarketplace
            partnerChannel = HubspotPartnerChannel.Marketplace

            hubspotFields.type_of_lead = LeadType.Sales
            hubspotFields.associated_partner_id = urlParams.get('associated_partner_id') || ''
        } else {
            if (integrationPage === 'visma') {
                pleoPartnerId = Integrations.Vismaeekonomi
            } else if (integrationPage === 'economic') {
                pleoPartnerId = Integrations.Vismaeconomic
            } else {
                pleoPartnerId = integrationPage as string
            }

            partnerChannel = HubspotPartnerChannel.TechnologyPartners

            hubspotFields.partnership_type = HubspotPartnershipType.Distribution
        }

        hubspotFields.pleo_partner_id = pleoPartnerId
        hubspotFields.partner_channel = partnerChannel
        hubspotFields.type_of_influence = HubspotTypeOfInfluence.PartnerSource
        hubspotFields.partner_segment = HubspotPartnerSegment.Referral

        const today = new Date()
        today.setUTCHours(0, 0, 0, 0)
        const timestamp = today.getTime()
        hubspotFields.partnership_referred_date = timestamp.toString()
    }

    // Landing Page fields
    if (landingPage === 'cashback') {
        hubspotFields.latest_campaign = MarketingCampaigns.Cashback
        hubspotFields.utm_campaign = MarketingCampaigns.Cashback
        hubspotFields.campaign__updating_ = MarketingCampaigns.Cashback
        hubspotFields.event_name = Events.CashbackLandingPage

        if (sourceIsBlog) {
            hubspotFields.event_name = Events.Blog
        }
    }

    if (
        landingPage === 'multi-entity-businesses' ||
        (sourceIsBlog && utmCampaign === 'multi-entity-businesses')
    ) {
        hubspotFields.latest_campaign = MarketingCampaigns.MultiEntity
        hubspotFields.utm_campaign = MarketingCampaigns.MultiEntity
        hubspotFields.campaign__updating_ = MarketingCampaigns.MultiEntity
        hubspotFields.event_name = sourceIsBlog ? Events.Blog : Events.MultiEntityLandingPage
    }

    return hubspotFields
}
