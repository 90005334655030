import type * as gatsby from 'gatsby'
import React from 'react'

import {WrapPageElement} from './src/config/wrap-page-element'
import {WrapRootElement} from './src/config/wrap-root-element'
import {grafana} from './src/observability'
import type {PageContext} from './src/types/page-context'

export * from './src/config/on-client-entry'
export * from './src/config/on-pre-route-update'
export * from './src/config/on-route-update'
export * from './src/config/on-initial-client-render'
export * from './src/config/register-service-worker'

export type DataType = Record<string, unknown>

export type GatsbyBrowser = gatsby.GatsbyBrowser<DataType, PageContext>

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => (
    <WrapRootElement>{element}</WrapRootElement>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element, props}) => (
    <WrapPageElement {...props}>{element}</WrapPageElement>
)

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({location}) => {
    grafana.api.pushEvent('navigation', {
        url: location.pathname,
    })
}
