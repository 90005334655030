export function isEqual<T extends Record<string, unknown>>(
    leftOperand: T,
    rightOperand: T,
): boolean {
    // Early return for same reference
    if (leftOperand === rightOperand) {
        return true
    }

    try {
        return JSON.stringify(leftOperand) === JSON.stringify(rightOperand)
    } catch (error) {
        return false // Fails gracefully if serialization fails
    }
}

/**
 * Omit a specific key from an object.
 *
 * @template T - The type of the input object.
 * @template K - The key to be omitted, which must be a key of `T`.
 *
 * @param {T} targetObject - The original object from which the key should be omitted.
 * @param {K} key - The key to omit from the object.
 * @returns {Omit<T, K>} - A new object with the specified key removed.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * const result = omit(obj, 'b');
 * console.log(result); // { a: 1, c: 3 }
 */
export const omit = <T extends Record<string, any>, K extends keyof T>(
    targetObject: T,
    key: K,
): Omit<T, K> => {
    const {[key]: ignored, ...rest} = targetObject
    return rest
}
