import Cookies from 'js-cookie'

import {cookiesMap} from '@/config/cookies'

const googleAdsCookies = [
    cookiesMap.adStorage,
    cookiesMap.adUserData,
    cookiesMap.adPersonalization,
    cookiesMap.analyticsStorage,
] as const

type CookieName = (typeof googleAdsCookies)[number]

export enum ConsentState {
    Denied = 'denied',
    Granted = 'granted',
}

export type ConsentParams = Partial<Record<CookieName, ConsentState>>

interface BaseOptions {
    cookiePrefix: 'managed' | 'unmanaged'
}

const getPrefixedCookieName = (entryName: string, {cookiePrefix}: BaseOptions): string => {
    return cookiePrefix === 'managed' ? `managed-${entryName}` : entryName
}

export const verifyInitialConsentsState = (options: BaseOptions) => {
    const consentsPayload: Partial<Record<CookieName, string>> = {}

    analytics.ready(() => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'default', {
                ad_storage: ConsentState.Denied,
                ad_user_data: ConsentState.Denied,
                ad_personalization: ConsentState.Denied,
                analytics_storage: ConsentState.Denied,
            })
        }
    })

    googleAdsCookies.forEach((cookie) => {
        const prefixedCookie = getPrefixedCookieName(cookie, options)

        if (Cookies.get(prefixedCookie) === undefined) {
            Cookies.set(prefixedCookie, ConsentState.Denied, {
                expires: 60,
                ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
            })
            consentsPayload[cookie] = ConsentState.Denied
        }

        consentsPayload[cookie] = Cookies.get(prefixedCookie)
    })

    analytics.ready(() => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'update', consentsPayload)
        }
    })
}

export const updateConsentsState = (consentParams: ConsentParams, options: BaseOptions) => {
    analytics.ready(() => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'update', consentParams)
        }
    })

    Object.entries(consentParams).forEach(([entryName, consentValue]) => {
        const prefixedCookie = getPrefixedCookieName(entryName, options)

        Cookies.set(prefixedCookie, consentValue, {
            expires: 60,
            ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
        })
    })
}
