// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Event fired when any Big Form is viewed or submitted
 */
/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {AnalyticsSnippet, Analytics, AnalyticsBrowser, Options} from '@segment/analytics-next'

export interface BigformActioned {
    action: BigformActionedAction
    field?: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum BigformActionedAction {
    Completed = 'completed',
    Interacted = 'interacted',
    Started = 'started',
}

/**
 * Event fired when the Big Form is submitted
 */
export interface BigformSubmitted {
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Big Form is viewed
 */
export interface BigformViewed {
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when a form from the contact page is submitted
 */
export interface ContactUsPageContactFormActioned {
    action: ContactUsPageContactFormActionedAction
    locale?: string
    origin?: string
    path?: string
    team: Team
    user_status: UserStatus
    [property: string]: any
}

export enum ContactUsPageContactFormActionedAction {
    Submitted = 'submitted',
}

export enum Team {
    Partnerships = 'partnerships',
    Support = 'support',
}

export enum UserStatus {
    LoggedIn = 'logged in',
    NotLoggedIn = 'not logged in',
}

/**
 * Event fired when an email from the contact page emails section is clicked.
 */
export interface ContactUsPageEmailLinkActioned {
    action: ContactUsPageEmailLinkActionedAction
    email?: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum ContactUsPageEmailLinkActionedAction {
    Completed = 'completed',
}

/**
 * Event fired when a CTA from the contact page hero section is clicked.
 */
export interface ContactUsPageHeroLinkActioned {
    action: ContactUsPageEmailLinkActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Intercom CTA is clicked from the contact page
 */
export interface ContactUsPageIntercomLinkActioned {
    action: ContactUsPageEmailLinkActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the cookie banner appears or is closes.
 */
export interface CookieBannerActioned {
    action: CookieBannerActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum CookieBannerActionedAction {
    Dismissed = 'dismissed',
    Shown = 'shown',
}

/**
 * Event fired when the cookie banner preferences are interacted with.
 */
export interface CookiePreferencesActioned {
    action: CookiePreferencesActionedAction
    cookiesAllowed?: boolean
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum CookiePreferencesActionedAction {
    Closed = 'closed',
    Opened = 'opened',
    Saved = 'saved',
}

/**
 * Event fired when the demo form is submitted
 */
export interface DemoBookingSubmitted {
    fbc?: string
    fbp?: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Booking page is viewed
 */
export interface DemoBookingViewed {
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Expense Policy Builder form is submitted
 */
export interface EpbActioned {
    action: EpbActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum EpbActionedAction {
    PdfDownloaded = 'pdf_downloaded',
    PdfGenerated = 'pdf_generated',
}

/**
 * Event fired from the Expense Report Generator when a step has been completed
 */
export interface ErgActioned {
    action: ErgActionedAction
    locale?: string
    origin?: string
    path?: string
    variant: Variant
    [property: string]: any
}

export enum ErgActionedAction {
    AlmostThereStepReached = 'almost_there_step_reached',
    ExpenseAdded = 'expense_added',
    ExpenseDeleted = 'expense_deleted',
    FirstInteraction = 'first_interaction',
    PdfDownloaded = 'pdf_downloaded',
    PdfGenerated = 'pdf_generated',
    PdfShared = 'pdf_shared',
    PreviewOpened = 'preview_opened',
    SuccessStepReached = 'success_step_reached',
}

export enum Variant {
    External = 'external',
    Internal = 'internal',
}

/**
 * Event fired when a user submits the sign up (email) form
 */
export interface EmailSignupActioned {
    action: EmailSignupActionedAction
    email?: string
    form?: string
    locale?: string
    origin?: string
    path?: string
    referral?: Referral
    [property: string]: any
}

export enum EmailSignupActionedAction {
    Blocked = 'blocked',
    Submitted = 'submitted',
}

export enum Referral {
    No = 'no',
    Yes = 'yes',
}

/**
 * Event fired from the Event Cards slice when a card CTA is clicked
 */
export interface EventCtaActioned {
    action: EventCtaActionedAction
    locale?: string
    origin?: string
    path?: string
    value?: string
    [property: string]: any
}

export enum EventCtaActionedAction {
    Clicked = 'clicked',
}

/**
 * Event fired from the Upcoming Events slice when a card CTA is clicked
 */
export interface EventsRegisterctaActioned {
    locale?: string
    location: string
    name: string
    origin?: string
    path?: string
    type: string
    [property: string]: any
}

/**
 * Event fired when a meeting has been booked.
 */
export interface GoogleAdsOptimisationSignal {
    currency?: string
    locale?: string
    origin?: string
    path?: string
    value?: number
    [property: string]: any
}

/**
 * Event fired when a homepage section becomes visible for the first time
 */
export interface HomepageActioned {
    action: HomepageActionedAction
    locale?: string
    origin?: string
    path?: string
    section?: string
    [property: string]: any
}

export enum HomepageActionedAction {
    HeroVideoClicked = 'hero_video_clicked',
    SectionViewed = 'section_viewed',
}

/**
 * Tracking the Sticky Nav that overlaps the main Nav in the Homepage. Fires if a use uses
 * that nav to navigate
 */
export interface HomepageStickyNavActioned {
    action: number
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Interactive Demo is actioned
 */
export interface InteractiveDemoActioned {
    action?: InteractiveDemoActionedAction
    section?: string
    [property: string]: any
}

export enum InteractiveDemoActionedAction {
    Viewed = 'viewed',
}

/**
 * Event fired when the Continue to Demo link is clicked on mobile in the explore-product
 * page
 */
export interface InteractiveDemoMobileGateContinueToDemoActioned {
    action: ContactUsPageEmailLinkActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the Back to Homepage link is clicked on mobile in the explore-product
 * page
 */
export interface InteractiveDemoMobileGateReturnHomepageActioned {
    action: ContactUsPageEmailLinkActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the invoice generator has been actioned
 */
export interface InvoiceGeneratorActioned {
    action?: BigformActionedAction
    field?: InvoiceGeneratorActionedField
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum InvoiceGeneratorActionedField {
    EmailSubmitted = 'email submitted',
    GetInvoice = 'get invoice',
    InvoiceDetails = 'invoice details',
    InvoiceItems = 'invoice items',
    NewInvoice = 'new invoice',
    Notes = 'notes',
    PdfDownloaded = 'pdf downloaded',
    Preview = 'preview',
    RecipientDetails = 'recipient details',
    YourCompanyDetails = 'your company details',
}

/**
 * Event fired from the invoices tracker when the sign up form is sumbitted
 */
export interface InvoiceSubscribeActioned {
    action: InvoiceSubscribeActionedAction
    email?: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum InvoiceSubscribeActionedAction {
    Subscribed = 'subscribed',
}

/**
 * Events fired from the Navattic interactive demo
 */
export interface NavatticEvent {
    checklist?: Checklist
    customerId?: string
    event?: Event
    flow?: Flow
    kind: Kind
    locale?: string
    origin?: string
    path?: string
    project?: Project
    sessionId?: string
    step?: Step
    url?: string
    [property: string]: any
}

export interface Checklist {
    id?: string
    name?: string
    [property: string]: any
}

export enum Event {
    CloseChecklist = 'CLOSE_CHECKLIST',
    CompleteFlow = 'COMPLETE_FLOW',
    Converted = 'CONVERTED',
    IdentifyUser = 'IDENTIFY_USER',
    Navigate = 'NAVIGATE',
    OpenChecklist = 'OPEN_CHECKLIST',
    StartChecklist = 'START_CHECKLIST',
    StartFlow = 'START_FLOW',
    ViewStep = 'VIEW_STEP',
}

export interface Flow {
    id?: string
    name?: string
    [property: string]: any
}

export enum Kind {
    NavatticEvent = 'navattic:event',
    NavatticStylesheetsLoaded = 'navattic:stylesheetsLoaded',
}

export interface Project {
    id?: string
    name?: string
    [property: string]: any
}

export interface Step {
    indx?: number
    name?: string
    [property: string]: any
}

/**
 * Event fired when the Blog newsletter form is submitted.
 */
export interface NewsletterSignupFormSubmitted {
    action: EmailSignupActionedAction
    email: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when a link from the notification (pink) banner is clicked.
 */
export interface NotificationBannerActioned {
    action: EventCtaActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when an internal page section becomes visible for the first time
 */
export interface PageActioned {
    action: PageActionedAction
    locale?: string
    origin?: string
    path?: string
    section?: string
    [property: string]: any
}

export enum PageActionedAction {
    SectionViewed = 'section_viewed',
}

/**
 * Event fired when a page gets google translated
 */
export interface PageTranslated {
    action: PageTranslatedAction
    language?: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum PageTranslatedAction {
    PageTranslated = 'page_translated',
}

export interface PageViewed {
    baseTag?: any[]
    bodyAttributes?: {[key: string]: any}
    defer?: boolean
    encode?: boolean
    htmlAttributes?: HtmlAttributes
    linkTags?: LinkTag[]
    metaTags?: MetaTag[]
    name?: string
    noscriptTags?: any[]
    path?: string
    rawPath?: string
    referrer?: string
    scriptTags?: ScriptTag[]
    search?: string
    styleTags?: any[]
    title?: string
    titleAttributes?: {[key: string]: any}
    url?: string
    [property: string]: any
}

export interface HtmlAttributes {
    lang?: string
    [property: string]: any
}

export interface LinkTag {
    href?: string
    hrefLang?: string
    rel?: string
    [property: string]: any
}

export interface MetaTag {
    content?: string
    name?: string
    property?: string
    [property: string]: any
}

export interface ScriptTag {
    async?: boolean
    charset?: string
    src?: string
    [property: string]: any
}

/**
 * Event fired when a parter cta from the partner-directory page is clicked
 */
export interface PartnerCtaActioned {
    action: EventCtaActionedAction
    locale?: string
    origin?: string
    path?: string
    value?: string
    [property: string]: any
}

/**
 * Event fired when we want to track qualifying leads after signing up.
 */
export interface PerformanceMarketingSignupActioned {
    action: PerformanceMarketingSignupActionedAction
    fbc?: string
    fbp?: string
    locale?: string
    origin?: string
    path?: string
    ttclid?: string
    value?: number
    [property: string]: any
}

export enum PerformanceMarketingSignupActionedAction {
    Started = 'started',
}

/**
 * Event fired from the Pricing page when a CTA from the plan cards is clicked
 */
export interface PlanSelected {
    locale?: string
    origin?: string
    path?: string
    /**
     * The name of the selected plan
     */
    value: Value
    [property: string]: any
}

/**
 * The name of the selected plan
 */
export enum Value {
    Advanced = 'advanced',
    Beyond = 'beyond',
    Essential = 'essential',
    Starter = 'starter',
}

/**
 * Event fired from the Pricing page when the plan toggle is used.
 */
export interface PricingBillingCycleActioned {
    action: PricingBillingCycleActionedAction
    locale?: string
    origin?: string
    path?: string
    selected?: Selected
    [property: string]: any
}

export enum PricingBillingCycleActionedAction {
    Changed = 'changed',
}

export enum Selected {
    Monthly = 'monthly',
    Yearly = 'yearly',
}

/**
 * Event fired from the Pricing page when the number of users was modified
 */
export interface PricingSliderActioned {
    numOfUsers?: number
    [property: string]: any
}

export interface PricingTooltipActioned {
    feature?: string
    [property: string]: any
}

/**
 * Event fired in the final step of the ROI calculator
 */
export interface RoicActioned {
    action: EpbActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired from the Resource Details page when the resource video is played
 */
export interface ResourceRequested {
    locale?: string
    origin?: string
    path?: string
    value: string
    [property: string]: any
}

/**
 * Event fired when the signup modal appears
 */
export interface SignupFormModalOpened {
    action: SignupFormModalOpenedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum SignupFormModalOpenedAction {
    Opened = 'opened',
}

/**
 * Event fired when the signup form from the signup modal is submitted
 */
export interface SignupFormSubmitted {
    locale?: string
    marketingConsent: boolean
    origin?: string
    path?: string
    privacyConsent: boolean
    [property: string]: any
}

/**
 * Event fired after successful, non-generic email submissions in eBook form.
 */
export interface SignupFunnelSignalEvent {
    company_size?: string
    companyCountry?: string
    /**
     * Flow origin (will replace conversionFlow)
     */
    conversion_flow?: string
    /**
     * Flow origin
     */
    conversionFlow?: string
    /**
     * Currency
     */
    currency?: string
    email?: string
    /**
     * Facebook click ID
     */
    fbc?: string
    /**
     * Facebook pixel ID
     */
    fbp?: string
    /**
     * Google click ID
     */
    gclid?: string
    hashed_email?: string
    isGeneric?: boolean
    metaOptimise?: boolean
    milestone?: string
    optimise?: boolean
    optimiseTest1?: boolean
    optimiseTest2?: boolean
    optimiseTest3?: boolean
    /**
     * LinkedIn click ID
     */
    uuid?: string
    value?: number
    [property: string]: any
}

/**
 * Event fired when the Use Case Slice is interacted with
 */
export interface SliceUseCaseActioned {
    action: SliceUseCaseActionedAction
    position?: any[]
    selection?: any[]
    [property: string]: any
}

export enum SliceUseCaseActionedAction {
    Completed = 'completed',
    CompletedWithoutSelection = 'completed without selection',
    Started = 'started',
}

/**
 * Event fired when the Slider arrow buttons are clicked
 */
export interface SliderCardsButtonActioned {
    action: EventCtaActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when the header CTA is clicked
 */
export interface TopNavigationSignupActioned {
    action: TopNavigationSignupActionedAction
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

export enum TopNavigationSignupActionedAction {
    Partners = 'partners',
    Started = 'started',
}

/**
 * Event fired when the invoice generator has been actioned
 */
export interface VatCalculatorActioned {
    action?: VatCalculatorActionedAction
    field?: VatCalculatorActionedField
    [property: string]: any
}

export enum VatCalculatorActionedAction {
    Actioned = 'actioned',
    Completed = 'completed',
    Interacted = 'interacted',
}

export enum VatCalculatorActionedField {
    Amount = 'amount',
    Calculate = 'calculate',
    Delete = 'delete',
    VatAmount = 'VAT % amount',
}

/**
 * Event fired when a webinar form is submitted
 */
export interface WebinarFormSubmitted {
    companySize: null | string
    country: null | string
    email: string
    firstName: string
    lastName: string
    locale?: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when a webinar video is played
 */
export interface WebinarVideoViewed {
    locale?: string
    name: string
    origin?: string
    path?: string
    [property: string]: any
}

/**
 * Event fired when a webinar CTA is clicked
 */
export interface WebinarsWatchNowctaActioned {
    locale?: string
    name: string
    origin?: string
    path?: string
    [property: string]: any
}

declare global {
    interface Window {
        analytics: AnalyticsSnippet
    }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (message: Record<string, any>, violations: any[]) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
    const msg = JSON.stringify(
        {
            type: 'Typewriter JSON Schema Validation Error',
            description:
                `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
                'Tracking Plan spec.',
            errors: violations,
        },
        undefined,
        2,
    )

    console.warn(msg)
}

let analytics: () => AnyAnalytics | undefined = () => {
    return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
    /**
     * Underlying analytics instance where analytics calls are forwarded on to.
     * Defaults to window.analytics.
     */
    analytics?: AnyAnalytics
    /**
     * Handler fired when if an event does not match its spec. This handler
     * does not fire in production mode, because it requires inlining the full
     * JSON Schema spec for each event in your Tracking Plan.
     *
     * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
     * if a message does not match the spec. Otherwise, errors will be logged to stderr.
     */
    onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
    analytics = options.analytics ? () => options.analytics || window.analytics : analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
    return {
        ...message,
        context: {
            ...(message.context || {}),
            typewriter: {
                language: 'typescript',
                version: '9.2.0',
            },
        },
    }
}

/**
 * Fires a 'BigformActioned' track call.
 *
 * @param BigformActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bigformActioned(
    props: BigformActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Bigform Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'BigformSubmitted' track call.
 *
 * @param BigformSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bigformSubmitted(
    props: BigformSubmitted,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Bigform Submitted',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'BigformViewed' track call.
 *
 * @param BigformViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bigformViewed(props: BigformViewed, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'Bigform Viewed',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ContactUsPageContactFormActioned' track call.
 *
 * @param ContactUsPageContactFormActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contactUsPageContactFormActioned(
    props: ContactUsPageContactFormActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Contact Us Page Contact Form Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ContactUsPageEmailLinkActioned' track call.
 *
 * @param ContactUsPageEmailLinkActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contactUsPageEmailLinkActioned(
    props: ContactUsPageEmailLinkActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Contact Us Page Email Link Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ContactUsPageHeroLinkActioned' track call.
 *
 * @param ContactUsPageHeroLinkActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contactUsPageHeroLinkActioned(
    props: ContactUsPageHeroLinkActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Contact Us Page Hero Link Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ContactUsPageIntercomLinkActioned' track call.
 *
 * @param ContactUsPageIntercomLinkActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contactUsPageIntercomLinkActioned(
    props: ContactUsPageIntercomLinkActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Contact Us Page Intercom Link Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'CookieBannerActioned' track call.
 *
 * @param CookieBannerActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cookieBannerActioned(
    props: CookieBannerActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Cookie Banner Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'CookiePreferencesActioned' track call.
 *
 * @param CookiePreferencesActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cookiePreferencesActioned(
    props: CookiePreferencesActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Cookie Preferences Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'DemoBookingSubmitted' track call.
 *
 * @param DemoBookingSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function demoBookingSubmitted(
    props: DemoBookingSubmitted,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Demo Booking Submitted',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'DemoBookingViewed' track call.
 *
 * @param DemoBookingViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function demoBookingViewed(
    props: DemoBookingViewed,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Demo Booking Viewed',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'EpbActioned' track call.
 *
 * @param EpbActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function epbActioned(props: EpbActioned, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'EPB Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ErgActioned' track call.
 *
 * @param ErgActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ergActioned(props: ErgActioned, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'ERG Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'EmailSignupActioned' track call.
 *
 * @param EmailSignupActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emailSignupActioned(
    props: EmailSignupActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Email Signup Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'EventCtaActioned' track call.
 *
 * @param EventCtaActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function eventCtaActioned(
    props: EventCtaActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Event Cta Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'EventsRegisterctaActioned' track call.
 *
 * @param EventsRegisterctaActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function eventsRegisterctaActioned(
    props: EventsRegisterctaActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Events Register CTA Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'GoogleAdsOptimisationSignal' track call.
 *
 * @param GoogleAdsOptimisationSignal props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function googleAdsOptimisationSignal(
    props: GoogleAdsOptimisationSignal,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Google Ads Optimisation Signal',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'HomepageActioned' track call.
 *
 * @param HomepageActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function homepageActioned(
    props: HomepageActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Homepage Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'HomepageStickyNavActioned' track call.
 *
 * @param HomepageStickyNavActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function homepageStickyNavActioned(
    props: HomepageStickyNavActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Homepage Sticky Nav Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'InteractiveDemoActioned' track call.
 *
 * @param InteractiveDemoActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function interactiveDemoActioned(
    props: InteractiveDemoActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Interactive Demo Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'InteractiveDemoMobileGateContinueToDemoActioned' track call.
 *
 * @param InteractiveDemoMobileGateContinueToDemoActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function interactiveDemoMobileGateContinueToDemoActioned(
    props: InteractiveDemoMobileGateContinueToDemoActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Interactive Demo Mobile Gate Continue to Demo Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'InteractiveDemoMobileGateReturnHomepageActioned' track call.
 *
 * @param InteractiveDemoMobileGateReturnHomepageActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function interactiveDemoMobileGateReturnHomepageActioned(
    props: InteractiveDemoMobileGateReturnHomepageActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Interactive Demo Mobile Gate Return Homepage Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'InvoiceGeneratorActioned' track call.
 *
 * @param InvoiceGeneratorActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoiceGeneratorActioned(
    props: InvoiceGeneratorActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Invoice Generator Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'InvoiceSubscribeActioned' track call.
 *
 * @param InvoiceSubscribeActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoiceSubscribeActioned(
    props: InvoiceSubscribeActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Invoice Subscribe Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'NavatticEvent' track call.
 *
 * @param NavatticEvent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function navatticEvent(props: NavatticEvent, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'Navattic Event',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'NewsletterSignupFormSubmitted' track call.
 *
 * @param NewsletterSignupFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newsletterSignupFormSubmitted(
    props: NewsletterSignupFormSubmitted,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Newsletter signup form submitted',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'NotificationBannerActioned' track call.
 *
 * @param NotificationBannerActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationBannerActioned(
    props: NotificationBannerActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Notification Banner Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PageActioned' track call.
 *
 * @param PageActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageActioned(props: PageActioned, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'Page Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PageTranslated' track call.
 *
 * @param PageTranslated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageTranslated(
    props: PageTranslated,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Page Translated',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageViewed(props: PageViewed, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'Page Viewed',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PartnerCtaActioned' track call.
 *
 * @param PartnerCtaActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function partnerCtaActioned(
    props: PartnerCtaActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Partner Cta Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PerformanceMarketingSignupActioned' track call.
 *
 * @param PerformanceMarketingSignupActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function performanceMarketingSignupActioned(
    props: PerformanceMarketingSignupActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Performance Marketing - Signup Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PlanSelected' track call.
 *
 * @param PlanSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelected(props: PlanSelected, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'Plan selected',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PricingBillingCycleActioned' track call.
 *
 * @param PricingBillingCycleActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pricingBillingCycleActioned(
    props: PricingBillingCycleActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Pricing Billing Cycle Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PricingSliderActioned' track call.
 *
 * @param PricingSliderActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pricingSliderActioned(
    props: PricingSliderActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Pricing Slider Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'PricingTooltipActioned' track call.
 *
 * @param PricingTooltipActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pricingTooltipActioned(
    props: PricingTooltipActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Pricing Tooltip Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'RoicActioned' track call.
 *
 * @param RoicActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function roicActioned(props: RoicActioned, options?: Options, callback?: Callback): void {
    const a = analytics()
    if (a) {
        a.track(
            'ROIC Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'ResourceRequested' track call.
 *
 * @param ResourceRequested props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function resourceRequested(
    props: ResourceRequested,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Resource Requested',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'SignupFormModalOpened' track call.
 *
 * @param SignupFormModalOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupFormModalOpened(
    props: SignupFormModalOpened,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Signup Form Modal Opened',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'SignupFormSubmitted' track call.
 *
 * @param SignupFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupFormSubmitted(
    props: SignupFormSubmitted,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Signup Form Submitted',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'SignupFunnelSignalEvent' track call.
 *
 * @param SignupFunnelSignalEvent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupFunnelSignalEvent(
    props: SignupFunnelSignalEvent,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Signup Funnel Signal Event',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'SliceUseCaseActioned' track call.
 *
 * @param SliceUseCaseActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sliceUseCaseActioned(
    props: SliceUseCaseActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Slice Use Case Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'SliderCardsButtonActioned' track call.
 *
 * @param SliderCardsButtonActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sliderCardsButtonActioned(
    props: SliderCardsButtonActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'SliderCards Button Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'TopNavigationSignupActioned' track call.
 *
 * @param TopNavigationSignupActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function topNavigationSignupActioned(
    props: TopNavigationSignupActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Top Navigation Signup Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'VatCalculatorActioned' track call.
 *
 * @param VatCalculatorActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function vatCalculatorActioned(
    props: VatCalculatorActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Vat Calculator Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'WebinarFormSubmitted' track call.
 *
 * @param WebinarFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webinarFormSubmitted(
    props: WebinarFormSubmitted,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Webinar Form Submitted',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'WebinarVideoViewed' track call.
 *
 * @param WebinarVideoViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webinarVideoViewed(
    props: WebinarVideoViewed,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Webinar Video Viewed',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}
/**
 * Fires a 'WebinarsWatchNowctaActioned' track call.
 *
 * @param WebinarsWatchNowctaActioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webinarsWatchNowctaActioned(
    props: WebinarsWatchNowctaActioned,
    options?: Options,
    callback?: Callback,
): void {
    const a = analytics()
    if (a) {
        a.track(
            'Webinars Watch Now CTA Actioned',
            props || {},
            {
                ...options,
                context: {
                    ...(options?.context || {}),
                    typewriter: {
                        language: 'typescript',
                        version: '',
                    },
                },
            },
            callback,
        )
    }
}

const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions,

    /**
     * Fires a 'BigformActioned' track call.
     *
     * @param BigformActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bigformActioned,
    /**
     * Fires a 'BigformSubmitted' track call.
     *
     * @param BigformSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bigformSubmitted,
    /**
     * Fires a 'BigformViewed' track call.
     *
     * @param BigformViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bigformViewed,
    /**
     * Fires a 'ContactUsPageContactFormActioned' track call.
     *
     * @param ContactUsPageContactFormActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contactUsPageContactFormActioned,
    /**
     * Fires a 'ContactUsPageEmailLinkActioned' track call.
     *
     * @param ContactUsPageEmailLinkActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contactUsPageEmailLinkActioned,
    /**
     * Fires a 'ContactUsPageHeroLinkActioned' track call.
     *
     * @param ContactUsPageHeroLinkActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contactUsPageHeroLinkActioned,
    /**
     * Fires a 'ContactUsPageIntercomLinkActioned' track call.
     *
     * @param ContactUsPageIntercomLinkActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contactUsPageIntercomLinkActioned,
    /**
     * Fires a 'CookieBannerActioned' track call.
     *
     * @param CookieBannerActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cookieBannerActioned,
    /**
     * Fires a 'CookiePreferencesActioned' track call.
     *
     * @param CookiePreferencesActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cookiePreferencesActioned,
    /**
     * Fires a 'DemoBookingSubmitted' track call.
     *
     * @param DemoBookingSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    demoBookingSubmitted,
    /**
     * Fires a 'DemoBookingViewed' track call.
     *
     * @param DemoBookingViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    demoBookingViewed,
    /**
     * Fires a 'EpbActioned' track call.
     *
     * @param EpbActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    epbActioned,
    /**
     * Fires a 'ErgActioned' track call.
     *
     * @param ErgActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    ergActioned,
    /**
     * Fires a 'EmailSignupActioned' track call.
     *
     * @param EmailSignupActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    emailSignupActioned,
    /**
     * Fires a 'EventCtaActioned' track call.
     *
     * @param EventCtaActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    eventCtaActioned,
    /**
     * Fires a 'EventsRegisterctaActioned' track call.
     *
     * @param EventsRegisterctaActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    eventsRegisterctaActioned,
    /**
     * Fires a 'GoogleAdsOptimisationSignal' track call.
     *
     * @param GoogleAdsOptimisationSignal props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    googleAdsOptimisationSignal,
    /**
     * Fires a 'HomepageActioned' track call.
     *
     * @param HomepageActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homepageActioned,
    /**
     * Fires a 'HomepageStickyNavActioned' track call.
     *
     * @param HomepageStickyNavActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    homepageStickyNavActioned,
    /**
     * Fires a 'InteractiveDemoActioned' track call.
     *
     * @param InteractiveDemoActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    interactiveDemoActioned,
    /**
     * Fires a 'InteractiveDemoMobileGateContinueToDemoActioned' track call.
     *
     * @param InteractiveDemoMobileGateContinueToDemoActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    interactiveDemoMobileGateContinueToDemoActioned,
    /**
     * Fires a 'InteractiveDemoMobileGateReturnHomepageActioned' track call.
     *
     * @param InteractiveDemoMobileGateReturnHomepageActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    interactiveDemoMobileGateReturnHomepageActioned,
    /**
     * Fires a 'InvoiceGeneratorActioned' track call.
     *
     * @param InvoiceGeneratorActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    invoiceGeneratorActioned,
    /**
     * Fires a 'InvoiceSubscribeActioned' track call.
     *
     * @param InvoiceSubscribeActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    invoiceSubscribeActioned,
    /**
     * Fires a 'NavatticEvent' track call.
     *
     * @param NavatticEvent props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    navatticEvent,
    /**
     * Fires a 'NewsletterSignupFormSubmitted' track call.
     *
     * @param NewsletterSignupFormSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newsletterSignupFormSubmitted,
    /**
     * Fires a 'NotificationBannerActioned' track call.
     *
     * @param NotificationBannerActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    notificationBannerActioned,
    /**
     * Fires a 'PageActioned' track call.
     *
     * @param PageActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pageActioned,
    /**
     * Fires a 'PageTranslated' track call.
     *
     * @param PageTranslated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pageTranslated,
    /**
     * Fires a 'PageViewed' track call.
     *
     * @param PageViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pageViewed,
    /**
     * Fires a 'PartnerCtaActioned' track call.
     *
     * @param PartnerCtaActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    partnerCtaActioned,
    /**
     * Fires a 'PerformanceMarketingSignupActioned' track call.
     *
     * @param PerformanceMarketingSignupActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    performanceMarketingSignupActioned,
    /**
     * Fires a 'PlanSelected' track call.
     *
     * @param PlanSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    planSelected,
    /**
     * Fires a 'PricingBillingCycleActioned' track call.
     *
     * @param PricingBillingCycleActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pricingBillingCycleActioned,
    /**
     * Fires a 'PricingSliderActioned' track call.
     *
     * @param PricingSliderActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pricingSliderActioned,
    /**
     * Fires a 'PricingTooltipActioned' track call.
     *
     * @param PricingTooltipActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pricingTooltipActioned,
    /**
     * Fires a 'RoicActioned' track call.
     *
     * @param RoicActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    roicActioned,
    /**
     * Fires a 'ResourceRequested' track call.
     *
     * @param ResourceRequested props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    resourceRequested,
    /**
     * Fires a 'SignupFormModalOpened' track call.
     *
     * @param SignupFormModalOpened props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signupFormModalOpened,
    /**
     * Fires a 'SignupFormSubmitted' track call.
     *
     * @param SignupFormSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signupFormSubmitted,
    /**
     * Fires a 'SignupFunnelSignalEvent' track call.
     *
     * @param SignupFunnelSignalEvent props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signupFunnelSignalEvent,
    /**
     * Fires a 'SliceUseCaseActioned' track call.
     *
     * @param SliceUseCaseActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    sliceUseCaseActioned,
    /**
     * Fires a 'SliderCardsButtonActioned' track call.
     *
     * @param SliderCardsButtonActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    sliderCardsButtonActioned,
    /**
     * Fires a 'TopNavigationSignupActioned' track call.
     *
     * @param TopNavigationSignupActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    topNavigationSignupActioned,
    /**
     * Fires a 'VatCalculatorActioned' track call.
     *
     * @param VatCalculatorActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    vatCalculatorActioned,
    /**
     * Fires a 'WebinarFormSubmitted' track call.
     *
     * @param WebinarFormSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webinarFormSubmitted,
    /**
     * Fires a 'WebinarVideoViewed' track call.
     *
     * @param WebinarVideoViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webinarVideoViewed,
    /**
     * Fires a 'WebinarsWatchNowctaActioned' track call.
     *
     * @param WebinarsWatchNowctaActioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webinarsWatchNowctaActioned,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
    get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method as keyof typeof clientAPI]
        }

        return () => {
            console.warn(`⚠️  You made an analytics call (${String(
                method,
            )}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`)
            const a = analytics()
            if (a) {
                a.track(
                    'Unknown Analytics Call Fired',
                    {
                        method,
                    },
                    withTypewriterContext(),
                )
            }
        }
    },
})
