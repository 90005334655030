import React from 'react'

import {isBrowser} from '@helios/website/src/utils/browser'

const ENCRYPTION_ALGORITHM = 'AES-GCM'

export const generateKey = async () => {
    return crypto.subtle.generateKey(
        {
            name: ENCRYPTION_ALGORITHM,
            length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
    )
}

export const encryptData = async (data: string, key: CryptoKey) => {
    const encodedData = new TextEncoder().encode(data)
    const iv = crypto.getRandomValues(new Uint8Array(12)) // Initialization vector

    const encryptedData = await crypto.subtle.encrypt(
        {
            name: ENCRYPTION_ALGORITHM,
            iv: iv,
        },
        key,
        encodedData,
    )

    return {
        iv: Array.from(iv),
        data: Array.from(new Uint8Array(encryptedData)),
    }
}

interface EncryptedData {
    iv: number[]
    data: number[]
}

export const decryptData = async (
    encryptedData: EncryptedData,
    key: CryptoKey,
): Promise<string> => {
    const iv = new Uint8Array(encryptedData.iv)
    const data = new Uint8Array(encryptedData.data)

    const decryptedData = await crypto.subtle.decrypt(
        {
            name: ENCRYPTION_ALGORITHM,
            iv: iv,
        },
        key,
        data,
    )

    return new TextDecoder().decode(decryptedData)
}

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>

/**
 *
 * @param key The key of the data to be stored in the storage
 * @param initialValue optional initial value to assign storage
 * @param storage: Storage | undefined: The storage is undefined when it is executed on the server
 * @desc: This hook abstracts the sessionStorage and the localStorage API to
 * access from and set data to sessionStorage and localStorage
 */
const useStorage = <T extends any>(
    key: string,
    initialValue: T,
    storage: Storage | undefined,
): [T, SetValue<T>] => {
    const [state, setState] = React.useState<T>(initialValue)

    // Get from storage then parse stored json or return initialValue
    const readValue = React.useCallback(async () => {
        if (!isBrowser || !storage) {
            return initialValue
        }

        try {
            const item = storage.getItem(key)
            if (item) {
                const encryptedData: EncryptedData = JSON.parse(item)
                const keyDataString = storage.getItem('encryptionKey')

                if (keyDataString) {
                    const keyData = JSON.parse(keyDataString)
                    const key = await crypto.subtle.importKey(
                        'jwk',
                        keyData,
                        {name: ENCRYPTION_ALGORITHM},
                        true,
                        ['decrypt'],
                    )
                    const decryptedData = await decryptData(encryptedData, key)

                    return JSON.parse(decryptedData)
                }

                return parseJSON(item)
            }

            return initialValue
        } catch (error) {
            console.warn(`Error reading ${storage} key “${key}”:`, error)
            return initialValue
        }
    }, [key, initialValue, storage])

    React.useEffect(() => {
        let isMounted = true

        const fetchValue = async () => {
            try {
                const value = await readValue()
                if (isMounted) {
                    setState(value)
                }
            } catch (error) {
                console.error('Error fetching value:', error)
            }
        }

        fetchValue()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setValue: SetValue<T> = async (value) => {
        if (!isBrowser || !storage) {
            return
        }

        try {
            const valueToStore = value instanceof Function ? value(state) : value

            // Retrieve or generate encryption key
            let keyDataString = storage.getItem('encryptionKey')
            let cryptoKey: CryptoKey

            if (keyDataString) {
                const keyData = JSON.parse(keyDataString)
                cryptoKey = await crypto.subtle.importKey(
                    'jwk',
                    keyData,
                    {name: ENCRYPTION_ALGORITHM},
                    true,
                    ['encrypt', 'decrypt'],
                )
            } else {
                cryptoKey = await generateKey()
                const exportedKey = await crypto.subtle.exportKey('jwk', cryptoKey)
                storage.setItem('encryptionKey', JSON.stringify(exportedKey))
            }

            // Encrypt data and store it
            const encryptedData = await encryptData(JSON.stringify(valueToStore), cryptoKey)
            storage.setItem(key, JSON.stringify(encryptedData))
            setState(valueToStore)
        } catch (error) {
            console.warn(`Error setting ${storage} key “${key}”:`, error)
        }
    }

    return [state, setValue]
}

// A wrapper for "JSON.parse()"" to support "undefined" value
const parseJSON = <T,>(value: string | null): T | undefined => {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch {
        console.warn('parsing error on', {value})
        return undefined
    }
}

export const useLocalStorage = <T extends any>(key: string, initialValue: T) =>
    useStorage<T>(key, initialValue, isBrowser ? localStorage : undefined)

export const useSessionStorage = <T extends any>(key: string, initialValue: T) =>
    useStorage<T>(key, initialValue, isBrowser ? sessionStorage : undefined)
