import {withLDProvider, useFlags as useLDFlags, useLDClient} from 'launchdarkly-react-client-sdk'
import React from 'react'

interface LaunchDarklyFlags {
    testFlag: boolean
    hubspotBanner: boolean
}

// Context for providing flags loaded status
const FeatureFlagsLoadedStatusContext = React.createContext<{
    loaded: boolean
    setLoaded: (loaded: boolean) => void
}>({
    loaded: false,
    setLoaded: () => {},
})

// Provides the loading status of LaunchDarkly flags.
function FlagsLoadedStatusProvider({children}: {children: React.ReactNode}) {
    const [loaded, setLoaded] = React.useState(false)

    return (
        <FeatureFlagsLoadedStatusContext.Provider value={{loaded, setLoaded}}>
            {children}
        </FeatureFlagsLoadedStatusContext.Provider>
    )
}

// Handles the status of the LD client.
function HandleLDClientStatus() {
    const ldClient = useLDClient()
    const {setLoaded} = React.useContext(FeatureFlagsLoadedStatusContext)

    React.useEffect(() => {
        if (!ldClient) return
        setLoaded(true)
    }, [ldClient, setLoaded])

    return null
}

/**
 * Wraps the app in a pre-configured LaunchDarkly provider (https://github.com/launchdarkly/react-client-sdk)
 * Passes all the information necessary to calculate the value of all the flags to LD when initializing
 */
export function LaunchDarklyProvider({
    clientSideID,
    children,
}: {
    clientSideID: string
    children: React.ReactNode
}) {
    const ldUser = {
        // All anonymous users share the same key. This means we can't vary the flag values before the users
        // log in, but at the same time allows us to save on the monthly active users count.
        // See https://docs.launchdarkly.com/home/users/anonymous-users#tracking-anonymous-users-with-a-shared-key
        key: 'anonymous_user_id',
        kind: 'user',
        anonymous: true,
        custom: {},
    }

    const App = () => (
        <FlagsLoadedStatusProvider>
            <HandleLDClientStatus />
            {children}
        </FlagsLoadedStatusProvider>
    )

    const LDApp = withLDProvider({
        clientSideID,
        context: ldUser,
        options: {
            // Saves the flags for the current user in LocalStorage, so that on the next full
            // page load the flags are available even before the request to LD is made, in a
            // stale-while-revalidate fashion.
            // @see https://docs.launchdarkly.com/sdk/features/bootstrapping?q=deferinitialization#bootstrapping-using-local-storage
            bootstrap: 'localStorage',
            // Some options that aim at limiting the number of requests we need to make to LaunchDarkly
            // @see https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
            diagnosticOptOut: true,
            fetchGoals: false,
            sendLDHeaders: false,
            sendEvents: false,
        },
    })(App)

    return <LDApp />
}

/**
 * Hook to check if LaunchDarkly flags have loaded.
 */
export function useFeatureFlagsLoaded() {
    const {loaded} = React.useContext(FeatureFlagsLoadedStatusContext)
    return loaded
}

/**
 * Hook to access LaunchDarkly flags.
 * We wrap it specify the type of flags we expect to get back.
 */
export function useFeatureFlags() {
    const flags = useLDFlags<LaunchDarklyFlags>()
    return flags
}
